// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-antarctica-js": () => import("./../../../src/pages/antarctica.js" /* webpackChunkName: "component---src-pages-antarctica-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-love-message-index-js": () => import("./../../../src/pages/love-message/index.js" /* webpackChunkName: "component---src-pages-love-message-index-js" */),
  "component---src-pages-love-message-js": () => import("./../../../src/pages/love-message/[...].js" /* webpackChunkName: "component---src-pages-love-message-js" */),
  "component---src-pages-your-love-map-js": () => import("./../../../src/pages/your-love-map.js" /* webpackChunkName: "component---src-pages-your-love-map-js" */)
}

